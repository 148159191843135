import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import type { ActivePanel, BreakpointsTypes, NewMenu, NewPanel, Widgets } from '@dev-fast/types';
import { BREAKPOINTS, ViewportType } from '@dev-fast/types';
import { Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import type { Observable } from 'rxjs';
import { filter, map } from 'rxjs';

import {
  ChangeActivePanel,
  ChangeActiveWidgets,
  LayoutState,
  OpenPanel,
  SetContentBreakpoint,
  SetLayoutType,
  ToggleBackground,
} from '@app/core/state/layout';

@Injectable({
  providedIn: 'root',
})
export class LayoutTypeService {
  readonly #window: Window | null = inject(DOCUMENT).defaultView;

  readonly activeMenu$: Observable<NewMenu[] | null> = inject(Store).select(LayoutState.activeMenu);
  readonly breakpoints$: Observable<BreakpointsTypes | null> = inject(Store).select(LayoutState.breakpoints);

  readonly nativeViewportType$: Observable<ViewportType> = this.breakpoints$.pipe(
    filter((breakpoints) => !!breakpoints?.native),
    map((breakpoints) => {
      if (breakpoints?.native === 'm' || breakpoints?.native === 's' || breakpoints?.native === 'xs' || breakpoints?.native === 'xxs') {
        return ViewportType.MOBILE;
      } else if (breakpoints?.native === 'l') {
        return ViewportType.TABLET;
      }
      return ViewportType.DESKTOP;
    }),
  );

  @Dispatch() setContentBreakpoint = (bp: string): SetContentBreakpoint => new SetContentBreakpoint(bp);
  @Dispatch() setNativeBreakpoint = (bp: string): SetLayoutType => new SetLayoutType(bp);
  @Dispatch() changeActivePanel = (panel: ActivePanel | null): ChangeActivePanel => new ChangeActivePanel(panel);
  @Dispatch() changeActiveWidgets = (widgets: Widgets[]): ChangeActiveWidgets => new ChangeActiveWidgets(widgets);
  @Dispatch() openRightPanel = (panel: NewPanel): OpenPanel => new OpenPanel(panel);
  @Dispatch() toggleBackground = (background?: string): ToggleBackground => new ToggleBackground(background);

  setBreakpoints(clientWidth: number): void {
    let contentBreakpoint = 'xxs';
    let nativeBreakpoint = 'xxs';
    Object.values(BREAKPOINTS).forEach((bp, index) => {
      if (clientWidth > bp) {
        contentBreakpoint = Object.keys(BREAKPOINTS)[index];
      }
      if (this.#window && this.#window.innerWidth > bp) {
        nativeBreakpoint = Object.keys(BREAKPOINTS)[index];
      }
    });
    this.setContentBreakpoint(contentBreakpoint);
    this.setNativeBreakpoint(nativeBreakpoint);
  }
}
